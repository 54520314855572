import { CSSProperties, useState } from 'react';
import './style.css';
// export const EllipsisText = ({
//   children = '',
//   maxLength = 40,
//   style,
//   direction = 'rtl',
//   fontWeight,
//   bold,
// }: {
//   children: string;
//   maxLength?: number;
//   style?: CSSProperties;
//   direction?: 'ltr' | undefined | 'rtl';
//   fontWeight?: CSSProperties['fontWeight'];
//   bold?: boolean;
// }) => {
//   if (children.length <= maxLength) {
//     return (
//       <span
//         style={{
//           fontWeight: bold ? 'bold' : fontWeight || 'normal',
//           direction,
//         }}
//       >
//         {children}
//       </span>
//     );
//   }

//   const truncatedText = children.substring(0, maxLength) + '...';

//   return (
//     <div
//       style={{
//         height: 50,
//         direction: direction,
//         ...style,
//         fontWeight: bold ? 'bold' : fontWeight || 'normal',
//       }}
//       title={children}
//     >
//       {truncatedText}
//     </div>
//   );
// };

export const EllipsisText = ({
  children = '',
  maxLength = 40,
  maxLines = 2,
  style,
  direction = 'ltr',
  fontWeight,
  fontSize,
  bold,
}: {
  children: string;
  maxLength?: number;
  style?: CSSProperties;
  direction?: 'ltr' | undefined | 'rtl';
  fontWeight?: CSSProperties['fontWeight'];
  fontSize?: CSSProperties['fontSize'];
  bold?: boolean;
  maxLines?: number;
}) => {
  return (
    <div
      className="ellipsis"
      style={{
        maxWidth: maxLength * 10,
        maxHeight: `${maxLines * 1.5}rem`,
        height: 250,
        width: '100%',
        direction,
        fontWeight: bold ? 'bold' : fontWeight,
        fontSize,
        // border: '1px solid black',
      }}
    >
      {children}
    </div>
  );
};

export const Ellipsis = ({
  children,
  maxLength = 25,
  style,
}: {
  children: string;
  maxLength?: number;
  style?: CSSProperties;
}) => {
  // const text='oshfsoiofhsdohdih'

  const [isTruncated] = useState(true);
  const truncatedText =
    children.length > maxLength && isTruncated
      ? children.substring(0, maxLength) + '...'
      : children;

  return <div style={style}>{truncatedText}</div>;
};
export const MultiLineEllipsis = ({
  children = '',
  maxLength = 25,
  style,
  direction = 'rtl',
  fontWeight,
  bold,
}: {
  children: string;
  maxLength?: number;
  style?: CSSProperties;
  direction?: 'ltr' | undefined | 'rtl';
  fontWeight?: CSSProperties['fontWeight'];
  bold?: boolean;
}) => {
  const longText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';
  return (
    <div className="ellipsis">
      {children}
      {/* {longText} */}
    </div>
  );
};

export const MultilineEllipsis = ({
  children = '',
  style,
  maxLines = 2,
  fontSize=15
}: {
  children?: string;
  style?: CSSProperties;
  maxLines?: number;
  fontSize?: CSSProperties['fontSize'];
}) => (
  <div
    style={{
      whiteSpace: 'pre-wrap',
      // whiteSpace: 'break-spaces',
      // border: '1px solid black',
    }}
  >
    <Ellipsis
      maxLength={maxLines * 60}
      style={{
        direction: /[\u0590-\u05FF]/.test(children) ? 'rtl' : 'ltr',
        ...style,
        fontSize
      }}
    >
      {children}
    </Ellipsis>
  </div>
);
