import { Link } from 'react-router-dom';
import { Card } from 'ui-kit/card';

export const NewsCard = ({ news }: { news: any }) => (
  <Card
  // style={{width:800}}
  containerStyle={{
    // border: '1px solid black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 15,
    direction: /[\u0590-\u05FF]/.test(news.title) ? 'rtl' : 'ltr',
  }}
  >
    {news.title && <h2>{news.title}</h2>}
    <img style={{ width: '100%', maxWidth: 450 }} alt="" src={news.image} />
    <h3>{news.brief}</h3>
    <div>{news.date}</div>
    <a
      href={news.url}
      target="_blank"
      rel="noopener noreferrer"
      className="link"
    >
      {"לכתבה המלאה"}
    </a>
    {/* <Link to={news.url}>{'לעוד פרטים'}</Link> */}
  </Card>
);
