import { useParams } from 'react-router';
import { Card } from 'ui-kit/card';
import { Guide } from 'models/Guide';
import { Section } from 'models/Section';
import { useCallback, useEffect, useRef, useState } from 'react';
import { imageHandler } from 'utils/ImageHandler';
import './style.css';
import { httpRequst } from 'services/api';

// Component to render each section
const SectionComponent: React.FC<{ section: Section }> = ({ section }) => (
  <div>
    <h2>{section.title}</h2>

    {section.code && (
      <pre
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          backgroundColor: '#1e1e1e',
          padding: '15px',
          borderRadius: '5px',
          marginBottom: '15px',
          fontFamily: 'monospace',
          overflowX: 'auto',
          direction: 'ltr',
        }}
      >
        {section.code.content}
      </pre>
    )}

    {section.content && (
      <Card
        style={{
          backgroundColor: '#121212',
          // alignSelf: 'center'
        }}
      >
        <p>{section.content}</p>
        {/* {section.duration && (
          <p>
            <strong>Duration:</strong> {section.duration}
          </p>
        )} */}
      </Card>
    )}
    {section.subsections &&
      section.subsections.map((sub, index) => (
        <div key={index} style={{ marginLeft: '20px', gap: 15 }}>
          <h3>{sub.title}</h3>
          {sub.content && <p>{sub.content}</p>}
          {sub.points && (
            <ul>
              {sub.points.map((point, idx) => (
                <li key={idx}>{point}</li>
              ))}
            </ul>
          )}
          {sub.code && (
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                backgroundColor: '#1e1e1e',
                padding: '15px',
                borderRadius: '5px',
                marginBottom: '15px',
                fontFamily: 'monospace',
                overflowX: 'auto',
                direction: 'ltr',
              }}
            >
              {sub.code.content}
            </pre>
          )}
        </div>
      ))}
    {section.points && (
      <ul>
        {section.points.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    )}
  </div>
);

const GuidePage = () => {
  const { guideId } = useParams<{ guideId: string }>();
  const [guide, setGuide] = useState<Guide>();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const observer = useRef<IntersectionObserver | null>(null);
  const hebrewRegex = /[\u0590-\u05FF]/;
  const direction = guide && hebrewRegex.test(guide.title) ? 'rtl' : 'ltr';
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const handleData = useCallback(async () => {
    try {
      if (guideId) {
        console.log('ID: ', guideId);
        const guideRes = await httpRequst('GET', 'guides', guideId);
        console.log('Guide: ', guideRes);
        setGuide(guideRes);
      }
    } catch (err) {
      setError(`${err}`);
    } finally {
      setLoading(false);
    }
  }, [setGuide, setError, setLoading, guideId]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = sectionRefs.current.indexOf(entry.target as HTMLDivElement);
          if (entry.isIntersecting) {
            setActiveIndex(index);
          }
        });
      },
      { threshold: 0.5 },
    );

    sectionRefs.current.forEach((section) => {
      if (section) {
        observer.current?.observe(section);
      }
    });
    if (loading) {
      handleData();
    }

    // Cleanup observer on component unmount
    return () => {
      observer.current?.disconnect();
    };
  }, [handleData, loading]);

  return loading ? (
    <div>Loafing...</div>
  ) : error ? (
    <div>Error: {error}</div>
  ) : (
    <div style={{ direction }}>
      {guide && (
        <aside
          className="side"
          style={{
            width: '20%',
            backgroundColor: '#2c2f36',
            padding: '20px',
            boxSizing: 'border-box',
            borderRight: '1px solid #343a40',
            position: 'fixed',
            height: '100vh',
            overflowY: 'auto', // Allow scrolling in sidebar
          }}
        >
          <h2 style={{ marginTop: '0' }}>Guide Sections</h2>
          <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
            {guide.sections.map((section, index) => (
              <li
                key={index}
                className={`sidebar-item ${activeIndex === index ? 'highlight' : ''}`}
                style={{
                  padding: '10px',
                  borderRadius: '5px',
                  transition: 'background-color 0.3s',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (sectionRefs.current[index]) {
                    sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                {section.title}
              </li>
            ))}
          </ul>
        </aside>
      )}

      {guide ? (
        <main
          className="main"
          style={{
            // marginInlineStart: '20%', // Account for fixed sidebar width
            padding: '20px',
            boxSizing: 'border-box',
            overflowY: 'auto', // Allow scrolling in main content
            height: '100vh', // Full viewport height
            // height: '100%',

            scrollbarWidth: 'none',
          }}
        >
          <header
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px',
              padding: '10px',
              backgroundColor: '#2c2f36',
              borderRadius: '8px',
              flexDirection: 'column',
            }}
          >
            <h1 style={{ margin: '0' }}>{guide.title}</h1>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 15,
                width: '100%',
                alignSelf: 'flex-start',
              }}
            >
              <p style={{ margin: '0', fontSize: '16px', color: '#b0b0b0' }}>
                <strong>Date:</strong> {new Date(guide.created_at).toLocaleDateString()}
              </p>
              <p style={{ margin: '10px 0', fontSize: '16px', color: '#b0b0b0' }}>
                <strong>Type:</strong> {guide.type}
              </p>
            </div>
            <Card>
              <img
                style={{
                  width: '100%',
                  // alignSelf: 'center',
                  height: '100%',
                }}
                src={guide.image || imageHandler('noImage')}
                alt=""
                // height={200}
              />
            </Card>
          </header>
          <Card style={{ color: 'black' }}>{guide.introduction}</Card>
          {guide.sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (sectionRefs.current[index] = el as HTMLDivElement)}
              style={{ marginBottom: '40px' }} // Add space between sections
            >
              <SectionComponent section={section} />
            </div>
          ))}

          {guide.example && (
            <div
              style={{
                marginTop: '30px',
                padding: '20px',
                backgroundColor: '#2c2f36',
                borderRadius: '8px',
              }}
            >
              {guide.example.title && <h2 style={{ marginTop: '0' }}>{guide.example.title}</h2>}
              <pre
                style={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  backgroundColor: '#1e1e1e',
                  padding: '15px',
                  borderRadius: '5px',
                  marginBottom: '15px',
                  fontFamily: 'monospace',
                  overflowX: 'auto',
                  direction: 'ltr',
                }}
              >
                {guide.example.code}
              </pre>
              {guide.example.explanation && (
                <p style={{ margin: '0', fontSize: '16px', lineHeight: '1.5' }}>
                  {guide.example.explanation}
                </p>
              )}
            </div>
          )}
        </main>
      ) : (
        <div>No Guide</div>
      )}


      {/* <aside
      className='side'
        style={{
          width: '20%',
          backgroundColor: '#2c2f36',
          padding: '20px',
          boxSizing: 'border-box',
          borderLeft: '1px solid #343a40',
          position: 'relative',
          // height: '100vh', // Full viewport height
        }}
      >
        <h2 style={{ marginTop: '0' }}>Additional Info</h2>
     </aside> */}
    </div>
  );
};

export default GuidePage;
