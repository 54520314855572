import { CSSProperties, ReactNode, useState } from "react";

import "./style.css";
import { imageHandler } from "utils/ImageHandler";

interface AnimatedButtonProps {
  children?: React.ReactNode;
  hover: ReactNode;
  onClick?: () => void;
  image?: string;
  text?: string;
  style?: CSSProperties;
}

export const HoverCard = ({
  children,
  hover = "No Hover Content",
  onClick,
  text = "No Text",
  image,
  style,
}: AnimatedButtonProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <div
      onClick={onClick}
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
      className="animated"
    >
      {/* {isHover && <div className="animatedHover">{hover}</div>} */}
      {isHover && <div className="animatedHover">{hover}</div>}
      {children ?? (
        <div
          className="children"
          style={{
            ...style,
          }}
        >
          <div className="img-container">
            <img
              alt={text}
              src={image || imageHandler("noImage")}
              // style={{
              //   width: '100%',
              // }}
              className="img"
            />
          </div>
          <h1>{text || "No Text"}</h1>
        </div>
      )}
    </div>
  );
};
