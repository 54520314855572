import { useEffect, useRef, useState } from "react";

import "./style.css";
import { imageHandler } from "utils/ImageHandler";

const scrollToTop = () => {
  // console.log('Scroll: ');

  window.scrollTo({
    behavior: "smooth",
    top: 0,
  });
};

export const ScrollUpButton = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const position = window.pageYOffset;
  const height = window.innerHeight;
  const offset = ref?.current ? ref.current.offsetHeight : 0;
  const offsety = ref?.current ? ref.current.offsetTop : 0;
  const size = offsety - height;
  const edge = position - size - offset;
  const pop = edge >= 1500;
  const [, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  return (
    <div className="scroll-up-button">
      {pop && (
        // <Tooltip content="Scroll Up" position={tooltipPostitionEnum.Down} disabled={false}>
        <img
          alt={"Scroll Up"}
          className="scroll-up-button"
          onClick={scrollToTop}
          src={
            "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmedia3.giphy.com%2Fmedia%2FMEuyrVS3UMe7DHm4Rq%2Fsource.gif&f=1&nofb=1&ipt=53f424576a5bcbb29f6d3e5cf6386f1ac47d358d5837b6e377c7f6d812050e9e&ipo=images"
          }
          height={60}
        />
        // </Tooltip>
      )}
    </div>
  );
};
export const Social = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const position = window.pageYOffset;
  const height = window.innerHeight;
  const offset = ref?.current ? ref.current.offsetHeight : 0;
  const offsety = ref?.current ? ref.current.offsetTop : 0;
  const size = offsety - height;
  const edge = position - size - offset;
  const pop = edge >= 1500;
  const [, setScrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        // bottom: "50%",
        top: 60,
        zIndex: 99999999,
      }}
      //  className="scroll-up-button"
    >
      {pop && (
        // <Tooltip content="Scroll Up" position={tooltipPostitionEnum.Down} disabled={false}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 15,
          }}
        >
          <a
            href="https://wa.me/972547599797"
            target="_parent"
            rel="noopener noreferrer"
          >
            <img
              src={imageHandler("whatsapp")}
              alt="WhatsApp"
              style={{ height: 50, margin: "0 10px" }}
            />
          </a>
          <a href="tel:+972547599797" >
          {/* Call Icon */}
          <img
            src={'https://icon-library.com/images/phone-call-icon-png/phone-call-icon-png-22.jpg'}
            alt="+972547599797"
            style={{ height: 50, margin: '0 10px' }}
          />
        </a>
          
          
          <a
            href="mailto:idan@idanzadki.com"
            target="_parent"
            rel="noopener noreferrer"
          >
            <img
              src={imageHandler("gmail")}
              alt="Gmail"
              style={{ height: 35, margin: "0 10px" }}
            />
          </a>
          <a
            href="https://www.facebook.com/JoyTechIT"
            target="_parent"
            rel="noopener noreferrer"
          >
            <img
              src={imageHandler("facebook")}
              alt="Facebook"
              style={{ height: 50, margin: "0 10px" }}
            />
          </a>
        </div>

        // </Tooltip>
      )}
    </div>
  );
};
