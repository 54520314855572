import { Guide } from 'models/Guide';
import { useNavigate } from 'react-router';
import { Card } from 'ui-kit/card';
import { imageHandler } from 'utils/ImageHandler';
import { Ellipsis, EllipsisText, MultilineEllipsis } from 'ui-kit/ellipsisText';
import './style.css';
import { Link } from 'react-router-dom';

interface GuideCardProps {
  guide: Guide;
  onClick?: (guide: Guide) => void;
}

export const GuideCard = ({ guide, onClick }: GuideCardProps) => {
  const navigate = useNavigate();
  return (
    <Card
      // style={{maxWidth:800,maxHeight:600}}
      containerStyle={{
        // border: '1px solid black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 15,
        direction: /[\u0590-\u05FF]/.test(guide.title) ? 'rtl' : 'ltr',
      }}
    >
      <div>{guide.type || 'No Type'}</div>

      <Ellipsis
        maxLength={35}
        style={{
          fontSize: 20,
          fontWeight: 'bold',
          height: '60px',
          // border:'1px solid black',
          direction: /[\u0590-\u05FF]/.test(guide.title) ? 'rtl' : 'ltr',
        }}
      >
        {guide.title}
      </Ellipsis>

      <img
        src={guide.image || imageHandler('JoyTechWeb')}
        alt={guide.title}
        className="guide-image"
        height={300}
        // style={{ height: 200, width: 400 }}
      />
      <div
        style={{
          // width: '80%',
          // border:'1px solid black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 3,
        }}
      >
        <MultilineEllipsis 
        fontSize={18}
        maxLines={1}>{`${guide.introduction}`}</MultilineEllipsis>
      </div>

      {/* <Link to={`/guides/${guide.id}`}>{'למדריך המלא'}</Link> */}


      <button
        onClick={() => {
          onClick
            ? onClick(guide)
            : navigate(`/guides/${guide.id || guide.gId}`, { state: { guide } });
        }}
      >
        {'לפרטים מלאים'}
      </button>
      <div style={{ textAlign: 'center' }}>{guide.created_at || 'No Date'}</div>
    </Card>
  );
};
