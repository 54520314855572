import { Article } from 'models/Article';
import { Card } from 'ui-kit/card';
import { Ellipsis, EllipsisText, MultilineEllipsis } from 'ui-kit/ellipsisText';
import { imageHandler } from 'utils/ImageHandler';

import './style.css';
import { Link } from 'react-router-dom';

interface ArticCardProps {
  article: Article;
  onClick?: (article: Article) => void;
}

export const ArticleCard = ({ article, onClick }: ArticCardProps) => (
  <Card
    containerStyle={{
      // border: '1px solid black',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 15,
      direction: /[\u0590-\u05FF]/.test(article.title) ? 'rtl' : 'ltr',
    }}
  >
    <div>{article.type || 'No Type'}</div>

    <Ellipsis
      maxLength={25}
      style={{
        fontSize: 25,
        fontWeight: 'bold',
        direction: /[\u0590-\u05FF]/.test(article.title) ? 'rtl' : 'ltr',
      }}
    >
      {article.title}
    </Ellipsis>

    <img
      src={article.image || imageHandler('JoyTechWeb')}
      alt={article.title}
      className="card-image"
      // height={60}
      // style={{ height: 200, width: 400 }}
    />

    <MultilineEllipsis fontSize={20} maxLines={1}>{`${article.introduction}`}</MultilineEllipsis>
    {/* <EllipsisText maxLength={80}>{`${article.sections[0].content}`}</EllipsisText> */}

    <button
      onClick={() => {
        onClick && onClick(article);
      }}
    >
      {'למאמר המלא'}
    </button>

    {/* <Link to={`/articles/${article.id}`}>{'למאמר המלא'}</Link> */}

    <div style={{ textAlign: 'center' }}>{article.created_at || 'No Date'}</div>
  </Card>
);
