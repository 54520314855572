import { CSSProperties, useEffect, useRef } from 'react';

type MyGenericProps = {
  title: string;
  list?: any[];
  renderItem?: (item: any) => JSX.Element;
  horizontal?: boolean;
  style?:CSSProperties;
  containerStyle?:CSSProperties
};

export const GenericList: React.FC<MyGenericProps> = ({
  title,
  list = [],
  renderItem = (item) => <div>No Render</div>,
  style,
  containerStyle,
  horizontal,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: MouseEvent) => {
    if (containerRef.current) {
      const { clientWidth, scrollWidth, scrollLeft } = containerRef.current;
      const offsetX = event.clientX - containerRef.current.getBoundingClientRect().left;

      const scrollSpeed = 10; // Adjust scroll speed as needed
      const edgeDistance = 10; // Distance from edge to start scrolling

      if (offsetX < edgeDistance) {
        containerRef.current.scrollLeft -= scrollSpeed;
      } else if (offsetX > clientWidth - edgeDistance) {
        containerRef.current.scrollLeft += scrollSpeed;
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('mousemove', handleMouseMove as EventListener);

      return () => {
        container.removeEventListener('mousemove', handleMouseMove as EventListener);
      };
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5,...style }}>
      <h1>{title}</h1>

      <div
        ref={containerRef}
        style={{
          flexDirection: 'row',
          display: 'flex',
          overflowX: 'scroll',
          gap: '10px',
          padding:10,
          borderRadius: 15,
          ...containerStyle
        }}
      >
        {list.length > 0 ? (
          list.map((item, index) => (
            <div key={index} style={{ display: 'inline-block' }}>
              {renderItem(item)}
            </div>
          ))
        ) : (
          <div>No List</div>
        )}
      </div>
    </div>
  );
};
