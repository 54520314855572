import Guides from 'pages/guides';
import JoyTech from 'pages/joytech';
import ProjectDetails from 'pages/project';
import Projects from 'pages/projects';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AddNewGuide from 'pages/addNewGuide';
import Forum from 'pages/forum';
import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useEffect,
} from 'react';
import Articles from 'pages/articles';
import GuidePage from 'pages/guide';
import ServicePage from 'pages/service';

import ArticlePage from 'pages/article';

export const AppRouter = () => (
  <BrowserRouter>
    {/* <Layout> */}
    <Routes>
      <Route path="/" element={<JoyTech />} />
      <Route path="/guides" element={<Guides />} />
      <Route path="/guides/:guideId" element={<GuidePage />} />
      <Route path="/articles/:slug" element={<ArticlePage />} />
      <Route path="/services/:serviceId" element={<ServicePage />} />
      {/* <Route path="/articles" element={<Articles />} /> */}
      {/* <Route path="/Forum" element={<Forum />} /> */}
      {/* <Route path="/addNewGuide" element={<AddNewGuide />} /> */}
      {/* <Route path="/projects" element={<Projects />} /> */}
      {/* <Route path="/projects/project" element={<ProjectDetails />} /> */}
    </Routes>
    {/* </Layout> */}
  </BrowserRouter>
);
  