// import { useLocation } from 'react-router';
// import { Link } from 'react-router-dom';
// import { Card } from 'ui-kit/card';

// const Article = () => {
//   const location = useLocation();
//   const { article } = location.state || {};

//   // const direction = article?.title.includes('א')||article?.title.includes('ה')||article?.title.includes('ו') ? 'rtl' : 'ltr';

//   const hebrewRegex = /[\u0590-\u05FF]/;
//   const direction = hebrewRegex.test(article.title) ? 'rtl' : 'ltr';

//   const metaInfoStyle = {
//     fontSize: '1.1em',
//     // color: '#555',
//     marginBottom: '10px',
//   };

//   const titleStyle = {
//     fontSize: '2.4em',
//     marginBottom: '15px',
//     // color: '#222',
//   };

//   const dateInfoStyle = {
//     fontSize: '1.1em',
//     color: '#777',
//     marginBottom: '20px',
//   };

//   const sectionStyle = {
//     marginBottom: '25px',
//     // color: 'black',
//   };

//   const sectionTitleStyle = {
//     fontSize: '2.2em',
//     borderBottom: '2px solid #ddd',
//     paddingBottom: '10px',
//     // color: '#111',
//   };

//   const contentStyle = {
//     fontSize: '1.1em',
//     lineHeight: '1.8',
//     // color: '#333',
//   };

//   const subsectionStyle = {
//     marginTop: '20px',
//   };

//   const subsectionTitleStyle = {
//     fontSize: '1.8em',
//     borderBottom: '1px solid #ddd',
//     paddingBottom: '5px',
//     // color: '#222',
//   };

//   const listStyle = {
//     fontSize: '1.1em',
//     lineHeight: '1.8',
//     // color: '#333',
//     paddingLeft: '20px',
//   };

//   const listItemStyle = {
//     marginBottom: '10px',
//   };

//   return article ? (
//     <div style={{  display:'flex'}}>
//       <div style={{ width: '20%' }} />
//       <div style={{ width: '100%' }}>
//         <h1 style={titleStyle}>{article.title}</h1>
//         <p style={metaInfoStyle}>{article.type}</p>
//         <p style={dateInfoStyle}>{article.created_at}</p>

//         {article.image && (
//           <Card style={{ backgroundColor: 'white' }}>
//             <img style={{ height: '100%', width: '100%' }} src={article.image} alt="Arduino" />
//           </Card>
//         )}
//         <Card style={{ margin: 10 }}>
//           {article.introduction && <p style={{ fontSize: 20 }}>{article.introduction}</p>}
//         </Card>
//         {article.sections.map(
//           (
//             section: {
//               title: string;

//               content: string | string[];

//               subsections: any[];
//             },
//             index: number | null | undefined,
//           ) => (
//             <div key={index} style={sectionStyle}>
//               <h2 style={sectionTitleStyle}>{section.title}</h2>

//               {section.content && <p style={contentStyle}>{section.content}</p>}
//               {section.subsections &&
//                 section.subsections.map((subsection, subIndex) => (
//                   <div key={subIndex} style={subsectionStyle}>
//                     <h3 style={subsectionTitleStyle}>{subsection.title}</h3>
//                     {subsection.content && (
//                       <ul style={listStyle}>
//                         {subsection.content.map((item: any, itemIndex: number) => (
//                           <li key={itemIndex} style={listItemStyle}>
//                             {item}
//                           </li>
//                         ))}
//                       </ul>
//                     )}
//                   </div>
//                 ))}
//             </div>
//           ),
//         )}
//         {article.conclution && (
//           <div style={sectionStyle}>
//             <h2>Conclution</h2>
//             {/* <p>{article.conclusion}</p> */}
//             <div style={{ fontSize: 18 }}>{article.conclution}</div>
//           </div>
//         )}

//         {article.sources && (
//           <div
//           // style={{border:'1px solid black'}}
//           >
//             <h2>{'מקורות'}</h2>
//             {article.sources.map((s: string) => (
//               <div style={{ direction: 'ltr', display: 'flex', gap: 5 }}>
//                 <div>{s.split(': ')[0]}:</div>
//                 <Link to={s.split(': ')[1]}>{s.split(': ')[1]}</Link>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//       <div style={{ width: '20%' }} />
//     </div>
//   ) : (
//     <div
//       style={{
//         textAlign: 'center',
//         marginTop: '50px',
//         fontSize: '1.5em',
//         color: '#888',
//       }}
//     >
//       No Article
//     </div>
//   );
// };

// export default Article;



import { useParams } from 'react-router';
import { Article } from 'models/Article';
import { Section } from 'models/Section';
import { useCallback, useEffect, useRef, useState } from 'react';
import { imageHandler } from 'utils/ImageHandler';
import { httpRequst } from 'services/api';
import { Card } from 'ui-kit/card';

// Component to render each section
const SectionComponent: React.FC<{ section: Section }> = ({ section }) => (
  <div>
    <h2>{section.title}</h2>

    {section.code && (
      <pre
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          backgroundColor: '#1e1e1e',
          padding: '15px',
          borderRadius: '5px',
          marginBottom: '15px',
          fontFamily: 'monospace',
          overflowX: 'auto',
          direction: 'ltr',
        }}
      >
        {section.code.content}
      </pre>
    )}

    {section.content && (
      <Card
        style={{
          backgroundColor: '#121212',
          // alignSelf: 'center'
        }}
      >
        <p>{section.content}</p>
        {/* {section.duration && (
          <p>
            <strong>Duration:</strong> {section.duration}
          </p>
        )} */}
      </Card>
    )}
    {section.subsections &&
      section.subsections.map((sub, index) => (
        <div key={index} style={{ marginLeft: '20px', gap: 15 }}>
          <h3>{sub.title}</h3>
          {sub.content && <p>{sub.content}</p>}
          {sub.points && (
            <ul>
              {sub.points.map((point, idx) => (
                <li key={idx}>{point}</li>
              ))}
            </ul>
          )}
          {sub.code && (
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
                backgroundColor: '#1e1e1e',
                padding: '15px',
                borderRadius: '5px',
                marginBottom: '15px',
                fontFamily: 'monospace',
                overflowX: 'auto',
                direction: 'ltr',
              }}
            >
              {sub.code.content}
            </pre>
          )}
        </div>
      ))}
    {section.points && (
      <ul>
        {section.points.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    )}
  </div>
);

const ArticlePage = () => {
  const { slug } = useParams<{ slug: string }>();
  const [article, setArticle] = useState<Article>();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const sectionRefs = useRef<HTMLDivElement[]>([]);
  const observer = useRef<IntersectionObserver | null>(null);
  const hebrewRegex = /[\u0590-\u05FF]/;
  const direction = article && hebrewRegex.test(article.title) ? 'rtl' : 'ltr';
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const handleData = useCallback(async () => {
    try {
      if (slug) {
        console.log('Slug: ', slug);
        const articleRes = await httpRequst('GET','articles',slug)
        console.log('Article: ', articleRes);
        setArticle(articleRes);
      }
    } catch (err) {
      setError(`${err}`);
    } finally {
      setLoading(false);
    }
  }, [setArticle, setError, setLoading, slug]);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = sectionRefs.current.indexOf(entry.target as HTMLDivElement);
          if (entry.isIntersecting) {
            setActiveIndex(index);
          }
        });
      },
      { threshold: 0.5 },
    );

    sectionRefs.current.forEach((section) => {
      if (section) {
        observer.current?.observe(section);
      }
    });
    if (loading) {
      console.log('Loading: ', loading);

      handleData();
    }

    // Cleanup observer on component unmount
    return () => {
      observer.current?.disconnect();
    };
  }, [ handleData, loading]);

  return loading ? (
    <div>Loafing...</div>
  ) : error ? (
    <div>Error: {error}</div>
  ) : (
    <div style={{ direction }}>
      {article && (
        <aside
          className="side"
          style={{
            width: '20%',
            backgroundColor: '#2c2f36',
            padding: '20px',
            boxSizing: 'border-box',
            borderRight: '1px solid #343a40',
            position: 'fixed',
            height: '100vh',
            overflowY: 'auto', // Allow scrolling in sidebar
          }}
        >
          <h2 style={{ marginTop: '0' }}>Article Sections</h2>
          <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
            {article.sections.map((section, index) => (
              <li
                key={index}
                className={`sidebar-item ${activeIndex === index ? 'highlight' : ''}`}
                style={{
                  padding: '10px',
                  borderRadius: '5px',
                  transition: 'background-color 0.3s',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (sectionRefs.current[index]) {
                    sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                {section.title}
              </li>
            ))}
          </ul>
        </aside>
      )}

      {article ? (
        <main
          className="main"
          style={{
            // marginInlineStart: '20%', // Account for fixed sidebar width
            padding: '20px',
            boxSizing: 'border-box',
            overflowY: 'auto', // Allow scrolling in main content
            height: '100vh', // Full viewport height
            // height: '100%',

            scrollbarWidth: 'none',
          }}
        >
          <header
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px',
              padding: '10px',
              backgroundColor: '#2c2f36',
              borderRadius: '8px',
              flexDirection: 'column',
            }}
          >
            <h1 style={{ margin: '0' }}>{article.title}</h1>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 15,
                width: '100%',
                alignSelf: 'flex-start',
              }}
            >
              <p style={{ margin: '0', fontSize: '16px', color: '#b0b0b0' }}>
                <strong>Date:</strong> {new Date(article.created_at).toLocaleDateString()}
              </p>
              <p style={{ margin: '10px 0', fontSize: '16px', color: '#b0b0b0' }}>
                <strong>Type:</strong> {article.type}
              </p>
            </div>
            <Card>
              <img
                style={{
                  width: '100%',
                  // alignSelf: 'center',
                  height: '100%',
                }}
                src={article.image || imageHandler('noImage')}
                alt=""
                // height={200}
              />
            </Card>
          </header>
          <Card style={{ color: 'black' }}>{article.introduction}</Card>
          {article.sections.map((section, index) => (
            <div
              key={index}
              ref={(el) => (sectionRefs.current[index] = el as HTMLDivElement)}
              style={{ marginBottom: '40px' }} // Add space between sections
            >
              <SectionComponent section={section} />
            </div>
          ))}

          {/* {article.example && (
            <div
              style={{
                marginTop: '30px',
                padding: '20px',
                backgroundColor: '#2c2f36',
                borderRadius: '8px',
              }}
            >
              {article.example.title && <h2 style={{ marginTop: '0' }}>{article.example.title}</h2>}
              <pre
                style={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  backgroundColor: '#1e1e1e',
                  padding: '15px',
                  borderRadius: '5px',
                  marginBottom: '15px',
                  fontFamily: 'monospace',
                  overflowX: 'auto',
                  direction: 'ltr',
                }}
              >
                {article.example.code}
              </pre>
              {article.example.explanation && (
                <p style={{ margin: '0', fontSize: '16px', lineHeight: '1.5' }}>
                  {article.example.explanation}
                </p>
              )}
            </div>
          )} */}
        </main>
      ) : (
        <div>No Article</div>
      )}

      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>Main</div>
      </div> */}

      {/* <aside
      className='side'
        style={{
          width: '20%',
          backgroundColor: '#2c2f36',
          padding: '20px',
          boxSizing: 'border-box',
          borderLeft: '1px solid #343a40',
          position: 'relative',
          // height: '100vh', // Full viewport height
        }}
      >
        <h2 style={{ marginTop: '0' }}>Additional Info</h2>
     </aside> */}
    </div>
  );
};

export default ArticlePage;
