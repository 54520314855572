import { useNavigate } from 'react-router';
import './style.css';
import { title } from 'process';
import { HoverCard } from 'pages/joytech/hoverCard';
import { useProfession } from 'hooks/useProfession';

export const ProfessionGrid = ({
  image,
  list,
}: {
  image?: string;
  list?: { text?: string; image?: any; hover?: string }[];
}) => {
  const navigate = useNavigate();

  return (
    <div className="profession-grid">
      {image && (
        <img
          alt={'Joytech'}
          src={
            image ||
            'https://media4.giphy.com/media/u1WhXLjwgcXpHJBMRM/200.webp?cid=790b7611wr1j2ucmdh4ucy8vgjkjnqnfxsecb9lvl72xpovu&ep=v1_gifs_search&rid=200.webp&ct=g'
          }
          className="grid-image"
        />
      )}

      <div className="cards-grid">
        {list?.map((pro) => (
          <HoverCard
            onClick={() => {
              navigate(`services/${pro.text}`, {
                state: {
                  service: pro.text,
                },
              });
            }}
            text={pro.text}
            image={pro.image}
            hover={pro.hover || ''}
          />
        ))}
      </div>
    </div>
  );
};

export const professionList: { text?: string; image?: any; hover?: string }[] = [
  // {
  //   text: 'טכנולוגיה',
  //   image:'https://i0.wp.com/www.2scaleup.com/wp-content/uploads/2019/01/stockvault-information-technology-concept174322.jpg?w=2900&ssl=1',
  //   hover: 'Networking, Sys Admin, Servers, Hardware',
  // },
  {
    text: 'מחשבים',
    image:
      'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.Njr_ya9PLVXD4Ae4DhEyNwHaEd%26pid%3DApi&f=1&ipt=0aa90bfc10dd32956558f5c855436ba38c891cba7903998e04b32b68a4a3a499&ipo=images',
    hover: 'מחשבים, מערכות הפעלה, חומרה',
  },
  {
    text: 'תוכנה',
    image:
      'https://i.pcmag.com/imagery/roundups/02HDufdqeRUDu3tl0NnY2qZ-2.fit_lim.size_760x427.v1649351854.jpg',
    hover: 'Front, Back, FullStack, Arduino',
  },
  {
    text: 'IT',
    image: 'https://www.augustatech.edu/skins/userfiles/images/IT-images/it-services.jpg',
    hover: 'Networking, Sys Admin, Servers, Hardware',
  },
  {
    text: 'חומרה',
    image:
      'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.Njr_ya9PLVXD4Ae4DhEyNwHaEd%26pid%3DApi&f=1&ipt=0aa90bfc10dd32956558f5c855436ba38c891cba7903998e04b32b68a4a3a499&ipo=images',
    hover: 'Computer and Laptops, PCB Scales',
  },
  {
    text: 'אינטגרציה',
    image:
      'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.Njr_ya9PLVXD4Ae4DhEyNwHaEd%26pid%3DApi&f=1&ipt=0aa90bfc10dd32956558f5c855436ba38c891cba7903998e04b32b68a4a3a499&ipo=images',
    hover: 'Front to Back, Back to front, Hardware to Back, Hardware to Front',
  },
  {
    text: 'IOT',
    image:
      'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.Njr_ya9PLVXD4Ae4DhEyNwHaEd%26pid%3DApi&f=1&ipt=0aa90bfc10dd32956558f5c855436ba38c891cba7903998e04b32b68a4a3a499&ipo=images',
    hover: 'Arduino, Raspberry Pie, BLE',
  },
];
const services = [
  {
    type: 'Software',
    professions: [
      {
        title: 'Mobile App Development',
        // dots: ['React Native','Android','Ionic'],
        dots: [
          'Characterization',
          'Develop from Scratch',
          'Full-Stack development',
          'UX/UI with our team',
        ],
      },

      {
        title: 'Web App Development',
        dots: ['React', 'WordPress'],
      },
      {
        title: 'Arduino',
        dots: ['Connect Arduino with IOT devices', 'Integration'],
      },
      {
        title: 'Raspberry PI',
        dots: ['BLE', 'Integration', 'OpenCV'],
      },
      {
        title: 'Image Processing',
        dots: ['BLE', 'Integration', 'OpenCV'],
      },
      {
        title: 'Computer Vision',
        dots: ['OpenCV'],
      },

      {
        title: 'פיתוח עמודי נחיתה',
        dots: ['Javascript', 'TypeScript', 'React JS/TS', 'WordPress'],
      },
      {
        title: 'פיתוח אפליקציות',
        dots: ['Javascript', 'TypeScript', 'React Native', 'Android', 'React Ionic'],
      },
      {
        title: 'פיתוח שרתים',
        dots: ['Node JS/TS', 'Python'],
      },
      {
        title: 'בסיסי נתונים',
        dots: ['MongoDB', 'PostgreSQL', 'MySQL', 'SQLite'],
      },
    ],
    image:
      'https://i.pcmag.com/imagery/roundups/02HDufdqeRUDu3tl0NnY2qZ-2.fit_lim.size_760x427.v1649351854.jpg',
  },
  {
    type: 'Computing',
    image:
      'https://www.lifewire.com/thmb/4XE6YS7Qv3nZBXBFd5dqWV1ilOs=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-939606046-9e72bdb3e2af4b5db49ff63925f4f6b3.jpg',
    professions: [
      {
        title: 'Network Managing',
        dots: ['Windows Server', 'Linux', 'Active Directory'],
      },

      {
        title: 'Operation Systems',
        dots: ['OS Troubleshooting', 'Formatting', 'Peripheral Installation'],
      },
      {
        title: 'Hardware maintenance',
        dots: ['Hardware Troubleshooting', 'PC Building', 'Upgrading', 'Peripheral Installation'],
      },
      {
        title: 'Help Desk',
        dots: ['Help Desk Support', 'Remote Assistance', 'Customer Service'],
      },
    ],
  },
  {
    type: 'Integration',
    professions: [
      {
        title: 'System Integration',
        dots: ['API Integration', 'Microservices', 'Enterprise Service Bus (ESB)'],
      },
      {
        title: 'בדיקות אינטגרציה',
        dots: ['Integration Testing', 'End-to-End Testing'],
      },
    ],
    image:
      'https://www.pngitem.com/pimgs/m/388-3887617_system-integration-clipart-system-integration-business-system-integration.png',
  },
  {
    type: 'IOT',
    professions: [
      {
        title: 'IOT Systems development',
        dots: ['Arduino', 'Raspberry Pi', 'ESP32'],
      },
      {
        title: 'IOT Security',
        dots: ['Encryption', 'Network Security', 'IoT Security Protocols'],
      },
    ],
    image:
      'https://img.freepik.com/premium-vector/iot-internet-things-devices-connectivity-concepts-network-flat-style-with-people_194782-1655.jpg?w=2000',
  },
  {
    type: 'IT',
    professions: [
      {
        title: 'IT Infrastructures',
        dots: ['Networking', 'Server Management', 'Cloud Infrastructure'],
      },
      {
        title: 'Data Security',
        dots: ['Cybersecurity', 'Firewalls', 'Intrusion Detection'],
      },
      {
        title: 'Cloud Services',
        dots: ['Hosting', 'Storing', 'Delivering'],
      },
    ],
    image:
      'https://swiftsystems.com/guides-tips/wp-content/uploads/2019/09/managed-it-services.jpg',
  },
];
export const Professions = ({
  image,
  list,
  title,
}: {
  image?: string;
  list?: any[];
  title?: string;
}) => {
  
  const navigate = useNavigate();
  const {professions}=useProfession()

  return (
    <div>
      {title && <h2>{title}</h2>}
      <div className="profession-grid">
        {image && (
          <img
            alt={'Services'}
            src={
              image ||
              'https://media4.giphy.com/media/u1WhXLjwgcXpHJBMRM/200.webp?cid=790b7611wr1j2ucmdh4ucy8vgjkjnqnfxsecb9lvl72xpovu&ep=v1_gifs_search&rid=200.webp&ct=g'
            }
            className="grid-image"
          />
        )}

        <div className="cards-grid">
          {professions?.map((pro, idx) => (
            <HoverCard
              key={idx}
              // style={{backgroundColor:'#e8eddf'}}
              onClick={() => {
                navigate(`services/${pro.type}`, {
                  state: {
                    service: pro,
                  },
                });
              }}
              text={pro.type}
              image={pro.image}
              hover={
                <div>
                  {pro.professions.map((pro: any, idx: number) => (
                    <h2 key={idx}>{pro.title}</h2>
                  ))}
                </div>
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};


export const ServiceGrid = ({
  image,
  list,
  title,
}: {
  image?: string;
  list?: any[];
  title?: string;
}) => {
  const navigate = useNavigate();

  return (
    <div>
      {title && <h1>{title}</h1>}
      <div className="profession-grid">
        {image && (
          <img
            alt={"Services"}
            src={
              image ||
              "https://media4.giphy.com/media/u1WhXLjwgcXpHJBMRM/200.webp?cid=790b7611wr1j2ucmdh4ucy8vgjkjnqnfxsecb9lvl72xpovu&ep=v1_gifs_search&rid=200.webp&ct=g"
            }
            className="grid-image"
          />
        )}

        <div className="cards-grid">
          {list?.map((pro, idx) => (
            <HoverCard
              key={idx}
              // style={{backgroundColor:'#e8eddf'}}
              onClick={() => {
                navigate(`/services/${pro.type}`, {
                  state: {
                    service: pro,
                  },
                });
              }}
              text={pro.type}
              image={pro.image}
              hover={
                <div>
                  {pro.professions.map((pro: any, idx: number) => (
                    <h3 key={idx}>{pro.title}</h3>
                  ))}
                </div>
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};
