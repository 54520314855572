import { useCallback, useEffect, useState } from "react"
import { Guide } from "../models/Guide";
import { httpRequst } from "../services/api";
import { Article } from "../models/Article";

export const useData = () => {

    const [guides, setGuides] = useState<Guide[]>([]);
    const [professions, setProfessions] = useState<any[]>([]);
    const [news, setNews] = useState<any[]>([]);

    const [articles, setArticles] = useState<Article[]>([]);


    const handleGetArticles = useCallback(async () => {
        const articleList = await httpRequst('GET', 'articles')
        setArticles(articleList)
    }, [setArticles]);


    const handleGetNews = useCallback(async () => {
        const list = await httpRequst('GET', 'news')
        setNews(list)
    }, [setNews]);

    const handleGetGuides = useCallback(async () => {
        const guideList = await httpRequst('GET', 'guides')
        setGuides(guideList)
    }, [setGuides]);

    const handleGetProfessions = useCallback(async () => {
        const professionList = await httpRequst('GET', 'fields')

        setProfessions(professionList)
    }, [setProfessions]);


    useEffect(() => {
        handleGetGuides()
        handleGetProfessions()
        handleGetArticles()
        handleGetNews()
    }, [handleGetGuides, handleGetProfessions, handleGetArticles, handleGetNews])




    return { guides, professions, news, articles }

}