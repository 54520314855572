export const images = {
    noImage: require('../assets/pictures/problem.png'),
    logoBlack: require('../assets/pictures/logoBlack.png'),
    logoWhite: require('../assets/pictures/logoWhite.png'),
    logoWhite2: require('../assets/pictures/JoyWhite2.png'),
    laptop1: require('../assets/pictures/Laptop1.png'),
    laptop2: require('../assets/pictures/Laptop2.png'),
    brain: require('../assets/pictures/brain.jpg'),
    JoyTechWeb: require('../assets/pictures/JoyTechWeb.png'),
    whatsapp: require('../assets/pictures/whatsapp.png'),
    facebook: require('../assets/pictures/facebook.png'),
    gmail: require('../assets/pictures/gmail.png'),

}

export type imageTypes=keyof typeof images

export const imageHandler = (pic: keyof typeof images) => {

    return pic in images ? images[pic] : images.noImage

}