import { ReactNode, useEffect, useRef, useState } from "react";
import { NewsCard } from "../newsCard";
import "./style.css";

export const AutoScroll = ({
  title,
  list,
  renderItem,
  height,
  width,
  speed = 15,
}: {
  title?: string;
  list?: any;
  renderItem?: (item: any) => ReactNode;
  height?: number;
  width?: number;
  speed?: number;
}) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (!scrollContainer) return;

    const scrollHeight = scrollContainer.scrollHeight;
    const clientHeight = scrollContainer.clientHeight;

    const scroll = () => {
      if (scrollContainer.scrollTop + clientHeight >= scrollHeight) {
        scrollContainer.scrollTop = 0;
      } else {
        scrollContainer.scrollTop += 1;
      }
    };

    const intervalId = setInterval(scroll, 20);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
    // style={{width}}
    >
      {title && <h2 style={{ textAlign: "center" }}>{title}</h2>}
      <div
        className="auto-scroll-container"
        style={
          {
            height: height,
            // width
            // '--height': `${height}px`,
            "--width": `${width}px`,
          } as React.CSSProperties
        }
      >
        <div
          className="auto-scroll"
          style={
            {
              "--speed": `${speed}s`,
            } as React.CSSProperties
          }
        >
          {list &&
            list.map((i: any, idx: number) => (
              <div key={idx}>{renderItem && renderItem(i)}</div>
            ))}
        </div>
      </div>
    </div>
  );
};

export const AutoScrollNews = ({
  list = [],
  speed = 50,
}: {
  list: any[];
  speed?: number;
}) => {
  const [scrollDirection, setScrollDirection] = useState<"up" | "down">("down");
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [scroll, setScroll] = useState(true);

  useEffect(() => {
    const scrollContainer = containerRef.current;

    const startAutoScroll = () => {
      if (scrollContainer) {
        scrollIntervalRef.current = setInterval(() => {
          const maxScroll =
            scrollContainer.scrollHeight - scrollContainer.clientHeight;
          const currentScroll = scrollContainer.scrollTop;

          if (scrollDirection === "down") {
            if (currentScroll >= maxScroll) {
              setScrollDirection("up");
            } else {
              scrollContainer.scrollTop += 1;
            }
          } else {
            if (currentScroll <= 0) {
              setScrollDirection("down");
            } else {
              scrollContainer.scrollTop -= 1;
            }
          }
        }, speed); // Adjust the speed of scrolling here
      }
    };

    if (scroll) startAutoScroll();

    return () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }
    };
  }, [scrollDirection, speed, scroll]);

  return (
    <div>
      <h1>חדשות</h1>
      <div
        ref={containerRef}
        style={{
          height: "600px", // Adjust height as needed
          overflow: "auto",
          // border: "1px solid #ccc",
          display: "flex",
          flexDirection: "column",
          gap: 10,
          margin: 10,
          padding: 15,
        }}
        onMouseEnter={() => {
          setScroll(false);
        }}
        onMouseLeave={() => setScroll(true)}
      >
        {list.map((news, index) => (
          <NewsCard key={news.id || index} news={news} />
        ))}
      </div>
    </div>
  );
};
