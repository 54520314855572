import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { Card } from 'ui-kit/card';

import { imageHandler } from 'utils/ImageHandler';
import { ScrollUpButton } from 'ui-kit/scrollUpButton';
import { HomeIntro } from 'ui-kit/homeIntro';
import { SectionWrapper } from 'ui-kit/sectionWrapper';
import { PopFromBottom } from 'ui-kit/popFromBottom';
import {  customerList } from 'consts/data';
import { useNavigate } from 'react-router';

import { AutoTyping } from 'components/autoTyping';
import { Professions, ServiceGrid } from 'components/professionGrid';
import { AutoScroll } from 'components/infiniteScroll';
import { Banner } from 'components/banner';

import { GuideCard } from './guideCard';
import { ArticleCard } from './articleCard';
import './style.css';
import { NewsCard } from './newsCard';
import { useGuide } from 'hooks/useGuide';
import { GenericList } from 'components/genericList';
import { FlipCard } from 'components/flipCard';
import { useNews } from 'hooks/useNews';
import { useArticle } from 'hooks/useArticle';
import { Social } from './scrollUpButton';
import { AutoScrollNews } from './autoScroll';
import { useData } from 'hooks/useData';

type MyListProps = {
  title: string;
  list?: any[];
  renderItem?: (item: any) => JSX.Element;
  style?: React.CSSProperties;
};

const MyList: React.FC<MyListProps> = ({
  title,
  list = [],
  renderItem = (item) => <div>No Render</div>,
  style,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: MouseEvent) => {
    if (containerRef.current) {
      const { clientWidth, scrollWidth, scrollLeft } = containerRef.current;
      const offsetX = event.clientX - containerRef.current.getBoundingClientRect().left;

      const scrollSpeed = 10; // Adjust scroll speed as needed
      const edgeDistance = 100; // Distance from edge to start scrolling

      if (offsetX < edgeDistance) {
        containerRef.current.scrollLeft -= scrollSpeed;
      } else if (offsetX > clientWidth - edgeDistance) {
        containerRef.current.scrollLeft += scrollSpeed;
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('mousemove', handleMouseMove as EventListener);

      return () => {
        container.removeEventListener('mousemove', handleMouseMove as EventListener);
      };
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, margin: 10 }}>
      <h2 style={{ fontSize: 22, textAlign: 'center', ...style }}>{title}</h2>
      <div style={{ display: 'flex' }}>
        <div
          ref={containerRef}
          style={{
            flexDirection: 'row',
            display: 'flex',
            overflowX: 'scroll',
            gap: '10px',
            padding: '15px',
            borderRadius: 15,
            // boxShadow: '1px 1px 3px 3px lightgray',
            whiteSpace: 'nowrap', // Prevent items from wrapping
          }}
        >
          {list.length > 0 ? (
            list.map((item, index) => (
              <div key={index} style={{ display: 'inline-block' }}>
                {renderItem(item)}
              </div>
            ))
          ) : (
            <div>No List</div>
          )}
        </div>
      </div>
    </div>
  );
};

interface ListItem {
  id: number;
  text: string;
}

const AutoScrollList: React.FC = () => {
  // const [items, setItems] = useState<ListItem[]>([]);
  const [items, setItems] = useState<ListItem[]>([]);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Initialize with some dummy data
    const initialItems: ListItem[] = Array.from({ length: 20 }, (_, index) => ({
      id: index,
      text: `Item ${index + 1}`,
    }));
    setItems(initialItems);

    // Set up the autoscroll
    const interval = setInterval(() => {
      if (listRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;

        // Check if we have reached the end of the scroll
        if (scrollTop + clientHeight >= scrollHeight) {
          // Add new items to the list
          setItems((prevItems) => {
            const nextId = prevItems.length;
            const newItems: ListItem[] = Array.from({ length: 10 }, (_, index) => ({
              id: nextId + index,
              text: `Item ${nextId + index + 1}`,
            }));
            return [...prevItems, ...newItems];
          });
        }

        // Scroll down
        listRef.current.scrollTop += 1;
      }
    }, 100); // Adjust the scroll speed as needed

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      ref={listRef}
      style={{
        height: '400px', // Set the height of the container
        overflowY: 'auto', // Enable vertical scrolling
        border: '1px solid #ccc',
      }}
    >
      <ul>
        {items.map((item) => (
          <li key={item.id} style={{ padding: '10px', borderBottom: '1px solid #eee' }}>
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

const PopSection = ({ complist, image }: { complist: any[]; image?: string }) => (
  <div className="pop_section">
    <img className="pop_image" src={image ?? require('../../assets/pictures/tap.jpg')} alt="" />

    <div>
      {complist.map((comp: any, idx) => (
        <PopFromBottom>
          <div style={{ display: 'flex', alignItems: 'center', direction: 'ltr' }}>
            <div
              style={{
                color: 'black',
                // color: idx % 2 === 1 ? 'blue' : 'white',
                textAlign: 'center',
                justifyContent: 'center',
                margin: '10px',
                fontSize: '40px',
              }}
            >
              <h2 style={{ color: 'blue' }}>{comp.title}</h2>
              {/* {comp.description} */}
            </div>
            <img alt="" src={require('../../assets/pictures/greenV.png')} height={30} />
          </div>
        </PopFromBottom>
      ))}
    </div>
  </div>
);

const CustomersScroll = () => (
  <SectionWrapper background="linear-gradient(to right, #000ff0, #8a2be2)">
    <div className="responsive-text">{'Happy Customers'}</div>
    <div style={{ fontSize: 35 }} className="responsive-text">
      {'All technology you need, with a package of joy.'}
    </div>
    <div className="customers">
      <Banner
        list={customerList}
        renderItem={(i) => (
          <Card
            style={{
              minWidth: 250,
              minHeight: 100,
            }}
          >
            <img style={{ width: '100%' }} alt={i.name} src={i.url} />
          </Card>
        )}
      />
      {/* <InfiniteAutoScroll list={customerList} /> */}
    </div>
    <div className="flip">
      <FlipCard>
        <img
          style={{ width: '100%' }}
          alt=""
          src={'https://hadarnet.com/wp-content/uploads/2021/01/2-1.png'}
        />
      </FlipCard>
      {/* {Array.from({ length: 10 }).map((i) => (
        <FlipCard>
          <img style={{ width: '100%' }} alt="" src={imageHandler('logoWhite')} />
        </FlipCard>
      ))} */}
    </div>
  </SectionWrapper>
);

const News = () => {
  const { news } = useNews();

  return (
    <AutoScroll
      width={1000}
      // speed={1}
      title="חדשות"
      list={news}
      renderItem={(i) => <NewsCard news={i} />}
    />
  );
  // <SectionWrapper>
  // </SectionWrapper>
};

const Articles = () => {
  const { articles } = useArticle();
  const navigate = useNavigate();

  return (
    <SectionWrapper>
      <MyList
        title="מאמרים"
        list={articles}
        renderItem={(article) => (
          <ArticleCard
            article={article}
            onClick={() => navigate(`/article/${article.id}`, { state: { article } })}
          />
        )}
      />
    </SectionWrapper>
  );
};

const Guides = () => {
  const { guides } = useGuide();

  return (
    <SectionWrapper>
      {/* <MyList title="מדריכים" list={guides} renderItem={(guide) => <GuideCard guide={guide} />} /> */}
      <GenericList
        title="מדריכים"
        list={guides}
        renderItem={(guide) => <GuideCard guide={guide} />}
      />
    </SectionWrapper>
  );
};

const PopServices = () => (
  <SectionWrapper background="linear-gradient(to right, black, grey)">
    <PopSection
      image="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2F2.bp.blogspot.com%2F-EzUh9sngxuk%2FV0LyUmh0aOI%2FAAAAAAAAAEo%2F-oX8w-1hpfwLs4z6DLOITTwHGW7StI-AQCLcB%2Fs1600%2FIT-Management.jpg&f=1&nofb=1&ipt=920a7c23d54c737a315183198250b92eed1cdbdfb0985c8f313c8664e1bee45a&ipo=images"
      complist={[
        { title: 'תוכנה' },
        { title: 'מחשבים' },
        { title: 'חומרה' },
        { title: 'מערכות הפעלה' },
        { title: 'שרתים' },
        { title: 'אפיוני מערכת וממשק משתמש' },
        { title: 'הקמת משרדים' },
        { title: 'שדרוג עמדות עבודה' },
        { title: 'אחסון אתרים' },
        { title: 'מוצרי ענן' },
        { title: 'בניית מפרטי מחשב' },
      ]}
    />
  </SectionWrapper>
);

const Footer = () => (
  <SectionWrapper>
    <div
      style={{
        textAlign: 'center',
        margin: '20px 0',
        minWidth: 200,
        // width: 600,
        alignSelf: 'center',
      }}
    >
      {/* <h3>מזהים משהו חסר? ישנה בעיה? אנחנו כאן תמיד!</h3> */}
      <div>
        <a href="https://wa.me/972547599797" target="_parent" rel="noopener noreferrer">
          <img
            src={require('../../assets/pictures/whatsapp.png')}
            alt="WhatsApp"
            style={{ height: 50, margin: '0 10px' }}
          />
        </a>
        <a href="https://www.facebook.com/JoyTechIT" target="_parent" rel="noopener noreferrer">
          <img
            src={require('../../assets/pictures/facebook.png')}
            alt="Facebook"
            style={{ height: 50, margin: '0 10px' }}
          />
        </a>
        <a href="mailto:joytechit@gmail.com" target="_parent" rel="noopener noreferrer">
          <img
            src={require('../../assets/pictures/gmail.png')}
            alt="Gmail"
            style={{ height: 40, margin: '0 10px' }}
          />
        </a>
      </div>
      {/* <div
    style={{
      direction: 'rtl',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '10px',
    }}
  >
    <input
      placeholder="שם פרטי"
      style={{
        margin: '5px 0',
        padding: 10,
        width: '80%',
      }}
    />
    <input
      placeholder="שם משפחה"
      style={{
        margin: '5px 0',
        padding: 10,
        width: '80%',
      }}
    />
    <input
      placeholder="אימייל"
      style={{
        margin: '5px 0',
        padding: 10,
        width: '80%',
      }}
    />
    <input
      placeholder="טלפון"
      style={{
        margin: '5px 0',
        padding: 10,
        width: '80%',
      }}
    />
    <button
      onClick={() => {
        console.log('Send MAIL');
      }}
      style={{
        margin: '10px 0',
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
      }}
    >
      שלח טופס
    </button>
  </div> */}
      <h3>התקשרו אלינו: 0547599797</h3>
      <h3>© copyright 2023 powered by JoyTech</h3>
    </div>
  </SectionWrapper>
);

const JoyTech1 = () => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);

  const { guides, handleGetGuides } = useGuide();
  // אני נהנים לעסוק וללמוד על טכנולוגיה ונוכל לסייע לכם בתחומי המחשוב, תוכנה, חומרה, שירותי ניהול רשתות, IT ו SYS Admin, תמיכה ופיתוח מוצרי IOT.
  const intro = `JoyTech Smart Solutions מלווה עסקים ופרטיים מ 2018, מעניקים פתרונות ושירותי ליווי ויעוץ במגוון תחומי הטכנולוגיה והאינטרנט.
פיתוח עמודי נחיתה, אתרים, אפליקציות, Dashboards מערכות רובוטיקה , IOT,  CRM ,ERP פיתוח מערכות , וכל מה שתוכלו לחשוב עליו.
אנו מאמינים בשמירה על מחירים הגיוניים עם סטנדרט גבוהה, לשם כך אנו עובדים עם מיטב אנשי ההנדסה, העיצוב והפיתוח.
`;
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        scrollbarWidth: 'none',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
      }}
      // className='joytech-home'
      ref={scrollRef}
    >
      <ScrollUpButton />

      <HomeIntro>
        <PopFromBottom>
          <img
            alt=""
            style={{
              width: '100%',
              minHeight: 100,
            }}
            src={imageHandler('logoWhite')}
          />
        </PopFromBottom>
        <div
          style={{
            fontSize: 52,
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
            margin: '20px 0',
          }}
        >
          Providing top-notch solutions for your business.
        </div>
        <AutoTyping
          // style={{ color:'#FFC600' }}
          style={{
            color: '#072AC8',
            fontWeight: 'bold',
            // fontFamily:'monospace'
            fontFamily: 'fantasy',
            height: 100,
            // border:'1px solid blue'
          }}
          fontSize={60}
          texts={['Software', 'IT', 'Computing', 'Arduino', 'Fullstack']}
        />
      </HomeIntro>

      {/* <CustomersScroll /> */}

      <SectionWrapper>
        <Card style={{ minWidth: 200, alignSelf: 'center', direction: 'rtl' }}>
          <h2>{'קצת עלינו'}</h2>
          <div
            style={{
              direction: 'rtl',
              fontSize: 28,
              textAlign: 'center',
              fontWeight: 500,
              lineHeight: 2,
            }}
          >
            {intro}
          </div>
          {/* <div style={{ color: 'red', fontSize: 30, fontWeight: 'bold' }}>
            {'האתר עדין בתהליך פיתוח, נשמח לפידבקים וביקורות :)'}
          </div> */}
        </Card>
      </SectionWrapper>

      <Professions
        // image="https://swiftsystems.com/guides-tips/wp-content/uploads/2019/09/managed-it-services.jpg  "
        title="השירותים שלנו"
      />

      <News />

      <Articles />

      {guides.length > 0 && <Guides />}

      {/* <PopServices /> */}
      <Footer />
    </div>
  );
};

const intro = `JoyTech Smart Solutions מלווה עסקים ופרטיים מ 2018, מעניקים פתרונות ושירותי ליווי ויעוץ במגוון תחומי הטכנולוגיה והאינטרנט.
פיתוח עמודי נחיתה, אתרים, אפליקציות, Dashboards מערכות רובוטיקה , IOT,  CRM ,ERP פיתוח מערכות , וכל מה שתוכלו לחשוב עליו,מעניקים שירותי מחשוב, פתרונות IT ולא מפחדים מטכנולוגיה :)
אנו מאמינים בשמירה על מחירים הגיוניים עם סטנדרט גבוה, לשם כך אנו עובדים עם מיטב הטכנאים, אנשי ההנדסה, העיצוב והפיתוח.
`;

const JoyTech = () => {
  const navigate = useNavigate();

  const { news, guides, professions, articles } = useData();

  
  return (
    <div
      style={{
        // fontFamily: 'Arial, sans-serif',
        scrollbarWidth: 'none',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
      }}
    >
      <ScrollUpButton />

      <Social />

      <HomeIntro>
        <PopFromBottom>
          <img alt="JoyTech-logo" className="pop_image" src={imageHandler('logoWhite2')} />
        </PopFromBottom>

        <h1 style={{ color: 'white' }}>Providing top-notch solutions for your business.</h1>

        <AutoTyping
          style={{
            color: '#072AC8',
            fontWeight: 'bold',
            fontFamily: 'fantasy',
            height: 100,
          }}
          fontSize={60}
          texts={['Software', 'IT', 'Computing', 'Arduino', 'Fullstack']}
        />

        {/* <Card style={{ minWidth: 200, alignSelf: 'center', direction: 'rtl' }}>
            <h1>{'קצת עלינו'}</h1>
            <p
              style={{
                direction: 'rtl',
                fontSize: 28,
                textAlign: 'center',
                fontWeight: 500,
                lineHeight: 2,
              }}
            >
              {intro}
            </p>
          </Card> */}
      </HomeIntro>

      <Card style={{ minWidth: 200, alignSelf: 'center', direction: 'rtl' }}>
        <h1>{'קצת עלינו'}</h1>
        <p
          style={{
            direction: 'rtl',
            fontSize: 28,
            textAlign: 'center',
            fontWeight: 500,
            lineHeight: 2,
          }}
        >
          {intro}
        </p>
      </Card>

      <ServiceGrid title="השירותים שלנו" list={professions} />

      <AutoScrollNews speed={20} list={news} />

      <MyList
        title="מאמרים"
        list={articles}
        renderItem={(article) => (
          <ArticleCard
            article={article}
            onClick={() => navigate(`/articles/${article.id}`, { state: { article } })}
          />
        )}
      />

      <GenericList
        title="מדריכים"
        list={guides}
        renderItem={(guide) => <GuideCard guide={guide} />}
      />

      <h3>התקשרו אלינו: 0547599797</h3>
      <h3>© copyright 2023 powered by JoyTech</h3>
    </div>
  );
};

export default JoyTech;
